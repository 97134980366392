.svd-toolbar-button {
  .svd-toolbar-button__image {
    line-height: 1em;
    display: inline-block;
    .svd-svg-icon {
      margin-top: -0.25em;
      margin-right: 0.25em;
    }
  }
  .svd-toolbar-button__title {
    line-height: 1em;
    display: inline-block;
  }

}