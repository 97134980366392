$primary-color: #1ab394;
$secondary-color: #1ab394;

$primary-hover-color: #18a689;
$selection-border-color: #1ab394;

$primary-text-color: #676a6c;
$secondary-text-color: #a7a7a7;
$inverted-text-color: #ffffff;

$primary-bg-color: #ffffff;
$secondary-bg-color: #f4f4f4;

$primary-border-color: #e7eaec;
$secondary-border-color: #ddd;

$dd-menu-border: #ccc;
$dd-menu-link-color: #262626;

$light-control-color: #d0d0d0;

$primary-icon-color: #3d4d5d;
$icon-status-color: #9d9fa1;

$error-color: #ed5565;
