.svd-embed-survey-tab {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.svd-embed-survey-tab .row {
  margin: 0;
}
.svd-embed-survey-tab > * {
  width: 100%;
}
.svd-embed-survey-tab .form-control.svd_custom_select.svd_embed_tab {
  display: inline-block;
  width: 250px;
  margin-bottom: 1em;
}
.svd-embed-survey-tab .form-control.svd_custom_select.svd_embed_tab:not(:last-child) {
  margin-right: 1em;
}