.svd-simulator {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  position: absolute;
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
  transform: scale(1.26) translate(-50%, -50%);
}

.svd-simulator-wrapper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.svd-simulator-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}
.svd-simulator-frame:before {
  content: " ";
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  display: block;
}
.svd-simulator-frame.svd-simulator-frame-landscape:before {
  transform: rotate(-90deg);
}
.svd-simulator-frame.svd-simulator-iphone4:before {
  background-image: url(../images/simulator/iPhone4.svg);
}
.svd-simulator-frame.svd-simulator-iphone5:before {
  background-image: url(../images/simulator/iPhone5.svg);
}
.svd-simulator-frame.svd-simulator-iphone6:before {
  background-image: url(../images/simulator/iPhone6.svg);
}
.svd-simulator-frame.svd-simulator-iphone6plus:before {
  background-image: url(../images/simulator/iPhone6Plus.svg);
}
.svd-simulator-frame.svd-simulator-iphone8:before {
  background-image: url(../images/simulator/iPhone8.svg);
}
.svd-simulator-frame.svd-simulator-iphone8plus:before {
  background-image: url(../images/simulator/iPhone8Plus.svg);
}
.svd-simulator-frame.svd-simulator-iphonex:before {
  background-image: url(../images/simulator/iPhoneX.svg);
}
.svd-simulator-frame.svd-simulator-iphonexmax:before {
  background-image: url(../images/simulator/iPhoneXMax.svg);
}
.svd-simulator-frame.svd-simulator-ipad:before {
  background-image: url(../images/simulator/iPad.svg);
}
.svd-simulator-frame.svd-simulator-ipadmini:before {
  background-image: url(../images/simulator/iPadMini.svg);
}
.svd-simulator-frame.svd-simulator-ipadpro:before {
  background-image: url(../images/simulator/iPadPro.svg);
}
.svd-simulator-frame.svd-simulator-ipadpro13:before {
  background-image: url(../images/simulator/iPadPro13.svg);
}
.svd-simulator-frame.svd-simulator-androidphone:before {
  background-image: url(../images/simulator/androidPhone.svg);
}
.svd-simulator-frame.svd-simulator-androidtablet:before {
  background-image: url(../images/simulator/androidTablet.svg);
}
.svd-simulator-frame.svd-simulator-win10phone:before {
  background-image: url(../images/simulator/win10Phone.svg);
}
.svd-simulator-frame.svd-simulator-mssurface:before {
  background-image: url(../images/simulator/msSurface.svg);
}
.svd-simulator-frame.svd-simulator-genphone:before {
  background-image: url(../images/simulator/device-p.svg);
}

.svd-simulator-content {
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
}