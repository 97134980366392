.propertyeditor-validators .first-addon {
  padding: 6px 12px;
}
.propertyeditor-validators .svd-available-classes-container {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  margin-top: -16px;
  font-size: 1.17em;
  display: none;
  background-color: #ffffff;
  background-color: var(--primary-bg-color, #ffffff);
}
.propertyeditor-validators .svd-available-classes-container li {
  margin: 5px 0;
  width: 100%;
}
.propertyeditor-validators .svd-available-classes-container li a {
  padding: 3px 20px;
  color: #262626;
  width: 100%;
  font-weight: 400;
  display: block;
}
.propertyeditor-validators .svd-available-classes-container li a:hover {
  background-color: #f4f4f4;
  text-decoration: none;
}
.propertyeditor-validators .svd-available-classes-container--active {
  display: block;
}