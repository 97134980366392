rating-item-editor {
  display: none;

  .svda-content {
    position: relative;
    pointer-events: none;

    .svda-title-editor-content {
      white-space: nowrap;

      &:first-child {
        margin-left: 5px;
      }
    }
    .svda-edit-button {
      padding-left: 5px;
      cursor: pointer;
    }
  }

  .svda-title-editor-start,
  .svda-delete-item {
    display: none;
  }
}

.svda-add-new-rating-item {
  pointer-events: all;
  padding: 0 1em;
  line-height: 2em;
  display: none;
}

.svd_q_selected {
  rating-item-editor {
    display: inline-block;

    .svda-content {
      pointer-events: all;
    }
  }

  .item_editable:hover {
    rating-item-editor {
      .svda-title-editor-start,
      .svda-delete-item {
        display: inline-block;
      }
    }
  }

  .svda-add-new-rating-item {
    display: inline-block;
  }
}
