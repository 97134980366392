.ddmenu-container {
  display: inline-block;
}
.ddmenu-container .ddmenu {
  position: relative;
}
.ddmenu-container .ddmenu > span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ddmenu-container .ddmenu > span,
.ddmenu-container .ddmenu > div {
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ddmenu-container .ddmenu > div {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.ddmenu-container .ddmenu > span:focus ~ div {
  display: block;
}
.ddmenu-container .ddmenu > ul {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0.5s;
  opacity: 0;
}
.ddmenu-container .ddmenu > ul li {
  list-style-type: none;
}
.ddmenu-container.menu-disabled .ddmenu > span:focus ~ ul {
  opacity: 0;
  visibility: hidden;
}
.ddmenu-container .ddmenu > span:focus ~ ul {
  visibility: visible;
  opacity: 1;
}