flowpanel-actions {
  display: none;
  position: relative;
  .svda_question_action {
    font-weight: 700;
    cursor: pointer;
    margin-left: 1em;
  }
}
.svd_q_selected > .svda-question-actions {
  flowpanel-actions {
    display: block;
  }

  .svda_action_separator {
    margin-left: 15px;
    margin-top: -5px;
  }
}

.svd_survey_designer {
  f-panel {
    .svda-question-actions {
      left: 0;
      right: 0;
      padding: 0 15px;
      background-color: rgba(255, 255, 255, 0.5);
      & > question-actions {
        position: relative;
      }
    }
  }
}
