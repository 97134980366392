svd-splitter, .row > svd-splitter {
  display: table-cell;
  min-width: 4px;
  width: 4px;
  max-width: 4px;
  cursor: ew-resize;
  position: relative;
}
svd-splitter div.svd-splitter, .row > svd-splitter div.svd-splitter {
  background-color: lightgray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
svd-splitter:hover > div.svd-splitter.svd-splitter.svd-splitter, svd-splitter.svd-active-splitter > div.svd-splitter.svd-splitter.svd-splitter, .row > svd-splitter:hover > div.svd-splitter.svd-splitter.svd-splitter, .row > svd-splitter.svd-active-splitter > div.svd-splitter.svd-splitter.svd-splitter {
  background-color: gray;
}
svd-splitter:hover > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon, svd-splitter.svd-active-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon, .row > svd-splitter:hover > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon, .row > svd-splitter.svd-active-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon {
  fill: gray;
}
svd-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split, .row > svd-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split {
  position: absolute;
  top: 50%;
  left: -6px;
}
svd-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon, .row > svd-splitter > div.svd-splitter.svd-splitter.svd-splitter > div.icon-split > svg.svd-svg-icon {
  width: 16px;
  height: 16px;
  fill: lightgray;
}