.svda-question-actions {
  position: absolute;
  right: 15px;
  top: -30px;
  left: 15px;
  text-align: right;
  line-height: 30px;
  min-width: 500px;
}
.svda-question-actions .svda_current_type {
  font-weight: 700;
  cursor: auto;
}
.svda-question-actions select.svda_current_type {
  border: none;
  outline: none;
  cursor: pointer;
}
.svda-question-actions select.svda_current_type:focus {
  border: none;
  outline: none;
}

question-actions {
  display: none;
}
question-actions .svda_question_action {
  font-weight: 700;
  cursor: pointer;
  margin-left: 1em;
}
question-actions .svda_question_action--separated {
  padding-left: 3em;
}

.svd_q_selected > .svda-question-actions question-actions {
  display: block;
}
.svd_q_selected > .svda-question-actions .svda_action_separator {
  margin-left: 15px;
  margin-top: -5px;
}