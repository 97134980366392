@import "../colors.scss";

.svd-propertyeditor-condition,
.propertyeditor-expression {
  width: 100%;

  textarea {
    // max-width: calc(100% - 200px);
    // min-width: calc(100% - 200px);
    max-width: calc(100%);
    min-width: calc(100%);
  }

  .assistant {
    padding-left: 1em;
    width: 200px;
    float: right;
    .assistant-title {
      margin-bottom: 0.5em;
      font-weight: bold;
    }
    .assistant-content {
      overflow-y: auto;
      height: 125px;
      .assistant-item {
        margin-bottom: 0.5em;
        cursor: pointer;
      }
    }
  }

  .svd_custom_select {
    width: auto;
    select:disabled {
      background-color: $secondary-bg-color;
      background-color: var(--secondary-bg-color, $secondary-bg-color);
    }
  }

  .svd-propertyeditor-condition-wide-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    margin: 10px 0;
    td {
      padding: 10px 0;
    }
    td:last-child {
      width: 20px;
      line-height: 28px;
      vertical-align: top;
    }

    .svd-propertyeditor-condition-item-delete-icon {
      float: right;
      cursor: pointer;
    }
  }
}

.svd_container survey-widget .sv_main {
  .sv_custom_header {
    display: none;
  }
  .sv_body.svd-property-editor-survey,
  .svd-property-editor-survey {
    background-color: transparent;
    border: none;
    padding: 0;
    .sv_p_root,
    .svd-property-editor-survey-page {
      .sv_page_title {
        display: none;
      }
      & > .sv_row {
        background-color: transparent;
        border: none;
        .sv_q,
        .sv_qstn {
          padding: 0;

          input,
          select,
          input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]) {
            height: 34px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.svd_container
  survey-widget
  .sv_main
  .svd-property-editor-survey
  .svd-property-editor-survey-page
  > .sv_row
  .sv_qstn
  .sv_q_matrix
  input {
  height: initial;
}

.svd-expression-header {
  background-color: #f0f0f0;
  margin-bottom: 1em;
  padding: 0.5em;
  cursor: pointer;

  .icon-expression-arrow {
    float: right;
    margin-top: -2px;
    margin-right: 7px;
  }
}
