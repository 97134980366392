.sjs-cb-wrapper {
  display: inline-block;
  margin-right: 1em;
  min-height: 2em;
}

.sjs-cb-container {
  position: relative;
  min-height: 1em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sjs-cb-container input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sjs-cb-container input:checked ~ .checkmark:after {
  display: block;
}

.sjs-cb-container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sjs-cb-label {
  line-height: 1.75em;
  margin-left: 35px;
}