.svd_container .svda_action_separator {
  display: inline-block;
  height: 16px;
  border-width: 0;
  border-left-width: 1px;
  border-style: solid;
  vertical-align: middle;
}
.svd_container svd-pages-editor {
  display: block;
  width: 100%;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}
.svd_container svd-pages-editor .svd-pages {
  white-space: nowrap;
  max-width: calc(100% - 480px);
  letter-spacing: -3px;
  overflow: hidden;
  vertical-align: bottom;
}
.svd_container svd-pages-editor .svd-pages.svd-pages--no-dropdown-selector {
  max-width: calc(100% - 230px);
}
.svd_container svd-pages-editor .svd-page-selector,
.svd_container svd-pages-editor .svd-page-scroller-arrow,
.svd_container svd-pages-editor .svd-page-add,
.svd_container svd-pages-editor .svd-pages,
.svd_container svd-pages-editor .svd-page {
  display: inline-block;
}
.svd_container svd-pages-editor .svd-page-scroller-arrow,
.svd_container svd-pages-editor .svd-page-add {
  cursor: pointer;
  padding: 0 1em;
}
.svd_container svd-pages-editor .svd-page-scroller-arrow svg,
.svd_container svd-pages-editor .svd-page-add svg {
  vertical-align: top;
}
.svd_container svd-pages-editor .svd-page-scroller-arrow {
  display: none;
  line-height: 4em;
  height: 4em;
  padding-top: 1.2em;
  vertical-align: bottom;
}
.svd_container svd-pages-editor .svd-page-scroller-arrow--visible {
  display: inline-block;
}
.svd_container svd-pages-editor .svd-page {
  overflow: hidden;
  vertical-align: bottom;
  letter-spacing: normal;
  cursor: pointer;
  height: 4em;
  padding: 1em 1.5em;
  font-weight: bold;
  border-width: 0;
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
}
.svd_container svd-pages-editor .svd-page.svd-border-right-none {
  border-right: none;
}
.svd_container svd-pages-editor .svd-page .svd-page-actions-container {
  display: inline-block;
  margin-left: 0.25em;
}
.svd_container svd-pages-editor .svd-page .svd-page-actions-container .svd-page-actions {
  box-shadow: 5px 5px 5px -5px #262626;
  position: absolute;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  visibility: hidden;
  z-index: 10;
}
.svd_container svd-pages-editor .svd-page .svd-page-actions-container .svd-page-actions .svda_action_separator {
  vertical-align: middle;
  margin-left: 0.5em;
}
.svd_container svd-pages-editor .svd-page .svd-page-actions-container .svd-page-actions .svd-page-action {
  margin-right: 0.5em;
  vertical-align: middle;
}
.svd_container svd-pages-editor .svd-page .svd-page-actions-container .svd-page-actions .svd-page-action:last-child {
  margin-left: 0.5em;
  display: inline-block;
}
.svd_container svd-pages-editor .svd-page:hover .svd-page-actions {
  visibility: visible;
}
.svd_container svd-pages-editor .svd-page-selector {
  max-width: 250px;
  line-height: 2.44em;
  height: 2.44em;
  border-width: 0;
  font-size: 14px;
  margin-bottom: 7px;
}

#svd-toolbar-page-selector .svd-toolbar-dropdown {
  margin-top: -6px;
}