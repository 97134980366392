.svd_container .svd_toolbox {
  border: none;
  font-size: 1.17em;
  margin-top: 10px;
}
.svd_container .svd_toolbox .panel-info {
  border: none;
}
.svd_container .svd_toolbox .panel-info.panel {
  background: transparent;
}
.svd_container .svd_toolbox .panel-info > .svd-toolbox-category-header {
  border: none;
  background-color: transparent;
  padding-left: 0;
  font-size: 1.17em;
  font-weight: 600;
  cursor: pointer;
  padding: 10px 15px;
}
.svd_container .svd_toolbox .panel-info > .svd-toolbox-category-header span {
  text-decoration: none;
  text-transform: uppercase;
}
.svd_container .svd_toolbox .panel-info > .svd-toolbox-category-header .icon-toolbox-arrow {
  float: right;
}
.svd_container .svd_toolbox .panel-info [role=tabpanel] {
  padding-left: 15px;
}
.svd_container .svd_toolbox .panel-info .svd-toolbox-category--collapsed {
  display: none;
}