.svd_container .svd_toolbar {
  margin-bottom: 1.17em;
}
.svd_container .svd_toolbar .svd_action {
  margin-right: 0.5em;
  margin-top: 0.5em;
  display: inline-block;
}
.svd_container .svd_toolbar .svd_state {
  float: right;
  line-height: 2.2em;
}
.svd_container .svd_toolbar .svd_state .icon {
  display: inline-block;
}
.svd_container .svd_toolbar .svd_state .icon + span {
  font-weight: bold;
}
.svd_container .svd_toolbar .ddmenu-container.toolbar-options ul {
  border: 1px solid #ccc;
  border-color: var(--dd-menu-border, #ccc);
  border-radius: 2px;
  padding: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  min-width: 160px;
  left: 0;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.svd_container .svd_toolbar .ddmenu-container.toolbar-options ul li {
  margin: 5px 0;
}
.svd_container .svd_toolbar .ddmenu-container.toolbar-options ul li a {
  width: 100%;
  white-space: nowrap;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  text-decoration: none;
}