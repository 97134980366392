@import "../colors.scss";

.svd_container {
  .svd_object_selector {
    display: block;
    width: 100%;
    padding: 15px;
    padding-right: 0;
    .svd-toolbar-dropdown {
      width: 100%;
      .svd_custom_select {
        width: 100%;
      }
    }
  }

  .svd_properties {
    height: 100%;
    border: none;
    padding: 0;
    margin-left: -0.5px;
    font-size: 1.17em;

    .modal {
      font-size: 0.857em;
      color: $primary-text-color;
      color: var(--primary-text-color, $primary-text-color);
    }
  }
}
