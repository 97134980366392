.table.svd_properties_grid .form-group {
  margin-bottom: 0;
}
.table.svd_properties_grid > tbody > tr > td {
  border: none;
  padding: 0;
  line-height: 3.25em;
  background-color: transparent;
  vertical-align: middle;
}
.table.svd_properties_grid > tbody > tr > td:nth-child(1) {
  font-weight: 600;
  user-select: none;
}
.table.svd_properties_grid > tbody > tr > td .modal-content {
  line-height: initial;
}