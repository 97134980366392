.svda-adorner-root {
  max-width: 100%;
}

.item_editable {
  pointer-events: all;
  cursor: text;
  position: relative;
}
.item_editable .svda-title-editor-start {
  cursor: pointer;
  visibility: hidden;
  padding-left: 5px;
}

.svd_q_selected .item_editable:hover .svda-title-editor-start {
  visibility: visible;
}

.svd_q_selected .item_editable item-editor .svda-focusable:focus + .svda-title-editor-start {
  visibility: visible;
  outline: 1px dotted #1ab394;
  outline-color: var(--primary-color, #1ab394);
}

.svda-drag-handle {
  cursor: move;
  visibility: hidden;
  padding-left: 5px;
}

.svd_q_selected .item_draggable:hover .svda-drag-handle {
  visibility: visible;
}

.svda-delete-item {
  cursor: pointer;
  visibility: hidden;
  padding-left: 5px;
}

.svd_q_selected .item_editable:hover .svda-delete-item {
  visibility: visible;
}

.svda-add-new-item {
  cursor: pointer;
  pointer-events: auto;
  padding-left: 16px;
  display: none;
}
.svda-add-new-item:hover {
  text-decoration: underline;
}

.svda-add-custom-item {
  color: #1ab394;
  color: var(--primary-color, #1ab394);
}
.svda-add-custom-item > span {
  vertical-align: middle;
  display: inline-block;
}

.svd_q_selected .svda-add-new-item {
  display: inline-block;
}

item-editor {
  display: none;
  max-width: 90%;
}
item-editor .svda-content {
  position: relative;
  pointer-events: all;
}
item-editor .svda-content .svda-title-editor-content {
  white-space: nowrap;
}
item-editor .svda-content .svda-title-editor-content > input {
  max-width: 100%;
  font-size: inherit;
  color: inherit;
}
item-editor .svda-content .svda-edit-button {
  padding-left: 5px;
  cursor: pointer;
}

.svd_q_selected item-editor {
  display: inline-block;
  outline: none;
}