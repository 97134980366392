@import "../colors";

.svd_container {
  .svda_action_separator {
    display: inline-block;
    height: 16px;
    border-width: 0;
    border-left-width: 1px;
    border-style: solid;
    vertical-align: middle;
  }

  svd-pages-editor {
    display: block;
    width: 100%;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;

    .svd-pages {
      white-space: nowrap;
      max-width: calc(100% - 480px);
      letter-spacing: -3px;
      overflow: hidden;
      vertical-align: bottom;
    }
    .svd-pages.svd-pages--no-dropdown-selector {
      max-width: calc(100% - 230px);
    }

    .svd-page-selector,
    .svd-page-scroller-arrow,
    .svd-page-add,
    .svd-pages,
    .svd-page {
      display: inline-block;
    }

    .svd-page-scroller-arrow,
    .svd-page-add {
      cursor: pointer;
      padding: 0 1em;
      svg {
        vertical-align: top;
      }
    }

    .svd-page-scroller-arrow {
      display: none;
      line-height: 4em;
      height: 4em;
      padding-top: 1.2em;
      vertical-align: bottom;
    }
    .svd-page-scroller-arrow--visible {
      display: inline-block;
    }

    .svd-page {
      overflow: hidden;
      vertical-align: bottom;
      letter-spacing: normal;
      cursor: pointer;
      height: 4em;
      padding: 1em 1.5em;
      font-weight: bold;
      border-width: 0;
      border-width: 1px;
      border-style: solid;
      border-bottom: none;
      &.svd-border-right-none {
        border-right: none;
      }
      .svd-page-actions-container {
        display: inline-block;
        margin-left: 0.25em;

        .svd-page-actions {
          box-shadow: 5px 5px 5px -5px $dd-menu-link-color;
          // box-shadow-color: var(--dd-menu-link-color, $dd-menu-link-color);
          position: absolute;
          padding: 5px;
          border-width: 1px;
          border-style: solid;
          visibility: hidden;
          z-index: 10;

          .svda_action_separator {
            vertical-align: middle;
            margin-left: 0.5em;
          }

          .svd-page-action {
            margin-right: 0.5em;
            vertical-align: middle;

            &:last-child {
              margin-left: 0.5em;
              display: inline-block;
            }
          }
        }
      }

      &:hover {
        .svd-page-actions {
          visibility: visible;
        }
      }
    }

    .svd-page-selector {
      max-width: 250px;
      line-height: 2.44em;
      height: 2.44em;
      border-width: 0;
      font-size: 14px;
      margin-bottom: 7px;
    }
    
  }
}

#svd-toolbar-page-selector .svd-toolbar-dropdown {
  // position: absolute;
  // left: 0;
  // top: 5px;
  margin-top: -6px;
}
