@import "../colors.scss";

.title_editable,
.description_editable {
  pointer-events: all;
  cursor: text;
  .svda-title-editor-start {
    cursor: pointer;
    visibility: hidden;
  }
}
.svd_q_selected .title_editable:hover,
.svd_q_selected .description_editable:hover {
  .svda-title-editor-start {
    visibility: visible;
  }
}

.svd_surveyjs_designer_container {
  .title_editable {
    .sv-title-actions {
      display: inline-block;
    }
  }
}

.svd_survey_header .title_editable,
.svd_survey_header .description_editable,
.svd_page > .title_editable,
.svd_page > .description_editable {
  .svda-title-editor-start {
    visibility: visible;
    .svda-title-editor-placeholder {
      cursor: text;
      opacity: 0.5;
    }
    .svda-title-editor-start-button {
      visibility: hidden;
    }
  }
  &:hover {
    .svda-title-editor-start {
      .svda-title-editor-start-button {
        visibility: visible;
      }
    }
  }
}

.svda-title-editor-focusable {
  visibility: hidden;
}
.svd_q_selected .svda-title-editor-focusable {
  visibility: visible;
}
.svd_q_selected title-editor {
  .svda-title-editor-focusable:focus + .svda-title-editor-start {
    visibility: visible;
    outline: 1px dotted $primary-color;
    outline-color: var(--primary-color, $primary-color);
  }
}

.svd_page > .description_editable {
  margin-bottom: 2em;
}

title-editor {
  display: inline-block;
  max-width: 90%;

  .svda-content {
    position: relative;
    pointer-events: all;

    .svda-title-editor-content {
      white-space: nowrap;
      & > input {
        max-width: 100%;
        font-size: inherit;
        color: inherit;
      }
      .svda-title-editor-error {
        outline: 1px dotted $error-color !important;
        outline-color: var(--error-color, $error-color);
        &::placeholder {
          color: $error-color;
          color: var(--error-color, $error-color);
        }
      }
    }
    .svda-edit-button {
      padding-left: 5px;
      cursor: pointer;
    }
  }
}
