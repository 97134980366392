.title_editable,
.description_editable {
  pointer-events: all;
  cursor: text;
}
.title_editable .svda-title-editor-start,
.description_editable .svda-title-editor-start {
  cursor: pointer;
  visibility: hidden;
}

.svd_q_selected .title_editable:hover .svda-title-editor-start,
.svd_q_selected .description_editable:hover .svda-title-editor-start {
  visibility: visible;
}

.svd_surveyjs_designer_container .title_editable .sv-title-actions {
  display: inline-block;
}

.svd_survey_header .title_editable .svda-title-editor-start,
.svd_survey_header .description_editable .svda-title-editor-start,
.svd_page > .title_editable .svda-title-editor-start,
.svd_page > .description_editable .svda-title-editor-start {
  visibility: visible;
}
.svd_survey_header .title_editable .svda-title-editor-start .svda-title-editor-placeholder,
.svd_survey_header .description_editable .svda-title-editor-start .svda-title-editor-placeholder,
.svd_page > .title_editable .svda-title-editor-start .svda-title-editor-placeholder,
.svd_page > .description_editable .svda-title-editor-start .svda-title-editor-placeholder {
  cursor: text;
  opacity: 0.5;
}
.svd_survey_header .title_editable .svda-title-editor-start .svda-title-editor-start-button,
.svd_survey_header .description_editable .svda-title-editor-start .svda-title-editor-start-button,
.svd_page > .title_editable .svda-title-editor-start .svda-title-editor-start-button,
.svd_page > .description_editable .svda-title-editor-start .svda-title-editor-start-button {
  visibility: hidden;
}
.svd_survey_header .title_editable:hover .svda-title-editor-start .svda-title-editor-start-button,
.svd_survey_header .description_editable:hover .svda-title-editor-start .svda-title-editor-start-button,
.svd_page > .title_editable:hover .svda-title-editor-start .svda-title-editor-start-button,
.svd_page > .description_editable:hover .svda-title-editor-start .svda-title-editor-start-button {
  visibility: visible;
}

.svda-title-editor-focusable {
  visibility: hidden;
}

.svd_q_selected .svda-title-editor-focusable {
  visibility: visible;
}

.svd_q_selected title-editor .svda-title-editor-focusable:focus + .svda-title-editor-start {
  visibility: visible;
  outline: 1px dotted #1ab394;
  outline-color: var(--primary-color, #1ab394);
}

.svd_page > .description_editable {
  margin-bottom: 2em;
}

title-editor {
  display: inline-block;
  max-width: 90%;
}
title-editor .svda-content {
  position: relative;
  pointer-events: all;
}
title-editor .svda-content .svda-title-editor-content {
  white-space: nowrap;
}
title-editor .svda-content .svda-title-editor-content > input {
  max-width: 100%;
  font-size: inherit;
  color: inherit;
}
title-editor .svda-content .svda-title-editor-content .svda-title-editor-error {
  outline: 1px dotted #ed5565 !important;
  outline-color: var(--error-color, #ed5565);
}
title-editor .svda-content .svda-title-editor-content .svda-title-editor-error::placeholder {
  color: #ed5565;
  color: var(--error-color, #ed5565);
}
title-editor .svda-content .svda-edit-button {
  padding-left: 5px;
  cursor: pointer;
}