select-items-editor, select-items-display {
  display: none;

  .svda-select-items-editor, .svda-select-items-display {
    position: relative;
    pointer-events: all;

    .svda-select-items-title {
      text-align: center;
      position: absolute;
      top: -2.2em;
      right: 2em;
      height: 2em;

      span {
        font-weight: bold;
        cursor: pointer;
      }
    }

    .svda-select-items-content {
      padding: 1em;
      border-style: solid;
      border-width: 1px;
      border-top: none;
    }
  }
}

.svd_q_selected  {
  select-items-editor, select-items-display {
    display: block;
  }
}
