@import "../colors";

@mixin svg-icon() {
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.form-control.svd_custom_select {
  padding: 0;
  min-width: 100px;
  select {
    border: none;
    height: 34px;
    padding: 6px 12px;
    padding-right: 3em;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  &:before {
    top: -1px;
  }
}

.svd_container .svd_content .svd_custom_select,
.svd_container .modal-dialog .svd_custom_select {
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: $primary-bg-color;
  background-color: var(--primary-bg-color, $primary-bg-color);
  box-sizing: content-box;

  select {
    width: 100%;
    display: block;
    position: relative;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 3em;

    &::-ms-expand {
      display: none;
    }
  }

  &:before {
    padding: 17px;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../images/ArrowDown_34x34.svg");
    @include svg-icon();
  }
}
