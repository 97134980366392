@import "../colors.scss";

.svda-title-actions {
  display: inline-block;
  position: absolute;
  max-width: 90%;
  top: 5px;
  right: 10px;
}

.svda-title-action__show-hide {
  vertical-align: top;
}

title-actions {
  display: inline-block;
  background-color: $secondary-bg-color;
  background-color: var(--secondary-bg-color, $secondary-bg-color);

  .svda_action_separator.svda_action_separator {
    margin-right: 10px;
    border: none;
  }
}

.svd_survey_header--hidden {
  height: 0;
  padding: 0;
  title-actions {
    background-color: transparent;
  }
}
.svd_survey_header--hidden > *:not(.svda-title-actions) {
  display: none !important;
}
