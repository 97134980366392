.image_editable {
  position: relative;
}

.svd_q_selected .image_editable image-editor,
.svd_survey_header .image_editable:hover image-editor {
  display: block;
}
.svd_q_selected .image_editable .svda-choose-file,
.svd_survey_header .image_editable:hover .svda-choose-file {
  pointer-events: all;
}

.svda-image-editor {
  overflow: hidden;
}

image-editor {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0 1em;
  border-radius: 0.5em;
  background-color: white;
  opacity: 0.75;
}
image-editor .svda-content {
  position: relative;
  pointer-events: all;
}
image-editor .svda-content .edit-image-item {
  cursor: pointer;
}
image-editor .svda-content .svda-delete-item {
  visibility: visible;
}