.svd-embed-survey-tab {
    width: 100%;
    height: 100%;
    overflow: auto;

    .row {
        margin: 0;
    }

    &> * {
        width: 100%;
    }

    .form-control.svd_custom_select.svd_embed_tab {
        display: inline-block;
        width: 250px;
        margin-bottom: 1em;
        &:not(:last-child) {
        margin-right: 1em;
        }
    }
}
