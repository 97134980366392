.svd_object_editor {
  padding: 1.4em;
}
.svd_object_editor .form-control {
  border-radius: 0;
}
.svd_object_editor input[type=checkbox].form-control {
  width: 25px;
}
.svd_object_editor survey-widget {
  display: block;
  margin-bottom: 1em;
}
.svd_object_editor survey-widget .sv_main .sv_custom_header {
  background: transparent;
}
.svd_object_editor survey-widget .sv_row {
  min-width: auto;
}
.svd_object_editor survey-widget .sv_main .sv_container .sv_body .sv_row .sv_q {
  min-width: auto;
}
.svd_object_editor survey-widget .svd-survey-nopadding {
  padding: 0;
  margin: 0;
}