.svd_test_results {
  padding: 1em;
}
.svd_test_results .svd_results_text {
  padding: 1em;
  white-space: pre-wrap;
  width: 100%;
  overflow: auto;
}
.svd_test_results .svd_results_table {
  padding: 1em;
  width: 100%;
  overflow: auto;
}
.svd_test_results .svd_results_table table {
  border-collapse: collapse;
  width: 100%;
}
.svd_test_results .svd_results_table table .survey-result-marker {
  position: absolute;
  left: 1em;
  top: 0.5em;
  transition: transform 0.2s ease-in-out;
}
.svd_test_results .svd_results_table table .survey-result-marker.survey-result-marker--expanded {
  transform: rotate(90deg);
}
.svd_test_results .svd_results_table table .survey-result-value {
  word-break: break-all;
}
.svd_test_results .svd_results_table table tr {
  vertical-align: top;
}
.svd_test_results .svd_results_table table th,
.svd_test_results .svd_results_table table td {
  border: 1px solid;
  padding: 0.5em 1em;
  max-width: 400px;
  overflow: auto;
}
.svd_test_results .svd_results_table table th:nth-child(1),
.svd_test_results .svd_results_table table td:nth-child(1) {
  position: relative;
  width: 30%;
  font-weight: 500;
  padding-left: 2em;
}
.svd_test_results .svd_results_table table th:nth-child(2),
.svd_test_results .svd_results_table table td:nth-child(2) {
  width: 20%;
}
.svd_test_results .svd_results_table table th:nth-child(3),
.svd_test_results .svd_results_table table td:nth-child(3) {
  width: 20%;
}
.svd_test_results .svd_results_table table th:nth-child(4),
.svd_test_results .svd_results_table table td:nth-child(4) {
  width: 30%;
  font-weight: 500;
}