select-items-editor, select-items-display {
  display: none;
}
select-items-editor .svda-select-items-editor, select-items-editor .svda-select-items-display, select-items-display .svda-select-items-editor, select-items-display .svda-select-items-display {
  position: relative;
  pointer-events: all;
}
select-items-editor .svda-select-items-editor .svda-select-items-title, select-items-editor .svda-select-items-display .svda-select-items-title, select-items-display .svda-select-items-editor .svda-select-items-title, select-items-display .svda-select-items-display .svda-select-items-title {
  text-align: center;
  position: absolute;
  top: -2.2em;
  right: 2em;
  height: 2em;
}
select-items-editor .svda-select-items-editor .svda-select-items-title span, select-items-editor .svda-select-items-display .svda-select-items-title span, select-items-display .svda-select-items-editor .svda-select-items-title span, select-items-display .svda-select-items-display .svda-select-items-title span {
  font-weight: bold;
  cursor: pointer;
}
select-items-editor .svda-select-items-editor .svda-select-items-content, select-items-editor .svda-select-items-display .svda-select-items-content, select-items-display .svda-select-items-editor .svda-select-items-content, select-items-display .svda-select-items-display .svda-select-items-content {
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  border-top: none;
}

.svd_q_selected select-items-editor, .svd_q_selected select-items-display {
  display: block;
}