.svd_property_editor_dropdown .svd-toolbar-dropdown {
  width: 100%;
}

.svd_container .svd_content .svd_property_editor_dropdown .svd-toolbar-dropdown .svd_custom_select {
  width: 100%;
}

.svd_property_editor_dropdown {
  min-width: 120px;
  display: block;
}

.svd_items_table .svd_property_editor_dropdown {
  min-width: 100px;
  width: 100px;
}

.select2-container--default .select2-selection--single {
  height: 34px;
  overflow: hidden;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  word-wrap: break-word;
  word-break: break-all;
  text-overflow: inherit;
  white-space: normal;
}
.select2-container--default .select2-results > .select2-results__options {
  width: 100%;
  overflow-x: hidden;
}