.svd-propertyeditor-condition,
.propertyeditor-expression {
  width: 100%;
}
.svd-propertyeditor-condition textarea,
.propertyeditor-expression textarea {
  max-width: 100%;
  min-width: 100%;
}
.svd-propertyeditor-condition .assistant,
.propertyeditor-expression .assistant {
  padding-left: 1em;
  width: 200px;
  float: right;
}
.svd-propertyeditor-condition .assistant .assistant-title,
.propertyeditor-expression .assistant .assistant-title {
  margin-bottom: 0.5em;
  font-weight: bold;
}
.svd-propertyeditor-condition .assistant .assistant-content,
.propertyeditor-expression .assistant .assistant-content {
  overflow-y: auto;
  height: 125px;
}
.svd-propertyeditor-condition .assistant .assistant-content .assistant-item,
.propertyeditor-expression .assistant .assistant-content .assistant-item {
  margin-bottom: 0.5em;
  cursor: pointer;
}
.svd-propertyeditor-condition .svd_custom_select,
.propertyeditor-expression .svd_custom_select {
  width: auto;
}
.svd-propertyeditor-condition .svd_custom_select select:disabled,
.propertyeditor-expression .svd_custom_select select:disabled {
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
}
.svd-propertyeditor-condition .svd-propertyeditor-condition-wide-table,
.propertyeditor-expression .svd-propertyeditor-condition-wide-table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  margin: 10px 0;
}
.svd-propertyeditor-condition .svd-propertyeditor-condition-wide-table td,
.propertyeditor-expression .svd-propertyeditor-condition-wide-table td {
  padding: 10px 0;
}
.svd-propertyeditor-condition .svd-propertyeditor-condition-wide-table td:last-child,
.propertyeditor-expression .svd-propertyeditor-condition-wide-table td:last-child {
  width: 20px;
  line-height: 28px;
  vertical-align: top;
}
.svd-propertyeditor-condition .svd-propertyeditor-condition-wide-table .svd-propertyeditor-condition-item-delete-icon,
.propertyeditor-expression .svd-propertyeditor-condition-wide-table .svd-propertyeditor-condition-item-delete-icon {
  float: right;
  cursor: pointer;
}

.svd_container survey-widget .sv_main .sv_custom_header {
  display: none;
}
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey,
.svd_container survey-widget .sv_main .svd-property-editor-survey {
  background-color: transparent;
  border: none;
  padding: 0;
}
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root .sv_page_title,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page .sv_page_title,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root .sv_page_title,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page .sv_page_title {
  display: none;
}
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row {
  background-color: transparent;
  border: none;
}
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_q,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_q,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn {
  padding: 0;
}
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_q input,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_q select,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn input,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn select,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q input,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q select,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn input,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn select,
.svd_container survey-widget .sv_main .sv_body.svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_q input,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_q select,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn input,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn select,
.svd_container survey-widget .sv_main .svd-property-editor-survey .sv_p_root > .sv_row .sv_qstn input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q input,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q select,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn input,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn select,
.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]) {
  height: 34px;
  line-height: 34px;
}

.svd_container survey-widget .sv_main .svd-property-editor-survey .svd-property-editor-survey-page > .sv_row .sv_qstn .sv_q_matrix input {
  height: initial;
}

.svd-expression-header {
  background-color: #f0f0f0;
  margin-bottom: 1em;
  padding: 0.5em;
  cursor: pointer;
}
.svd-expression-header .icon-expression-arrow {
  float: right;
  margin-top: -2px;
  margin-right: 7px;
}