.svd-logic-tab {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.svd-logic-tab__rules-list {
  width: 100%;
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #404040;
  border-collapse: collapse;
}

.svd-logic-tab__rules-list__header {
  font-weight: bold;
}

.svd-logic-tab__rules-list__row {
  border-bottom: solid 1px #ddd;
  border-bottom-color: var(--secondary-border-color, #ddd);
}
.svd-logic-tab__rules-list__row th,
.svd-logic-tab__rules-list__row td {
  padding: 10px;
}

.svd-logic-tab__rules-list__action-cell {
  text-align: center;
}
.svd-logic-tab__rules-list__action-cell > * {
  cursor: pointer;
}

.svd-logic-tab__rule-editor__title {
  font-size: 20px;
  font-weight: bold;
  color: #404040;
}

.svd-logic-tab__item {
  border: solid 1px #ddd;
  border-color: var(--secondary-border-color, #ddd);
  padding: 20px;
  margin-bottom: 20px;
}

.svd-logic-tab__item-header {
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  line-height: 34px;
}

.svd-logic-tab__item-comtent {
  padding-top: 20px;
}

.svd-logic-tab__item-header-icon {
  float: right;
  cursor: pointer;
  padding-right: 10px;
}

.svd-logic-tab-item-header {
  background-color: #e0e0e0;
  min-height: 32px;
  line-height: 32px;
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 14px;
  padding: 0 1em;
}
.svd-logic-tab-item-header .svd-logic-header-item-icon {
  float: right;
  cursor: pointer;
  padding-right: 10px;
}
.svd-logic-tab-item-header .svd-logic-header-item-icon svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.svd-logic-tab-item-header .svd-logic-header-item-icon svg-icon svg {
  display: block;
  margin-top: 3px;
}
.svd_container .svd_content .svd-logic-tab .svd-expression-header {
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}
.svd_container .svd_content .svd-logic-tab .icon-expression-arrow {
  margin-right: 10px;
}
.svd_container .svd_content .svd-logic-tab .svd-propertyeditor-condition {
  border: solid 1px #ddd;
  border-color: var(--secondary-border-color, #ddd);
  padding: 20px;
}
.svd_container .svd_content .svd-logic-tab .svd-expression-content {
  margin-top: 20px;
}
.svd_container .svd_content .svd-logic-tab .form-group {
  margin-bottom: 0;
}
.svd_container .svd_content .svd-logic-tab .btn {
  height: 34px;
}
.svd_container .svd_content .svd-logic-tab .btn-link {
  border: solid 1px #ddd;
  border-color: var(--secondary-border-color, #ddd);
}
.svd_container .svd_content .svd-logic-tab .alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.svd_container .svd_content .svd-logic-tab .alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.svd_container .svd_content .svd-logic-tab .svd-danger-icon .svd-svg-icon {
  fill: #ed5565;
  fill: var(--error-color, #ed5565);
}