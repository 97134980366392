.sjs-cb-switch .sjs-cb-switch__container {
  display: inline-block;
  width: 38.5px;
}
.sjs-cb-switch .sjs-cb-switch__label {
  vertical-align: top;
  margin-left: 1em;
}
.sjs-cb-switch .sjs-cb-switch_control {
  position: relative;
  cursor: pointer;
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  height: 22px;
  border: 1px solid #ccc;
  border-color: var(--dd-menu-border, #ccc);
  border-radius: 100px;
  transition: background-color 0.25s ease-out, border-color 0.25s ease-out;
}
.sjs-cb-switch input {
  display: none;
}
.sjs-cb-switch .sjs-cb-switch__button {
  pointer-events: none;
  position: absolute;
  transition: right 0.25s ease-out, background-color 0.25s ease-out, border-color 0.25s ease-out;
  right: 45%;
  height: 100%;
  width: 55%;
  background-color: #ddd;
  background-color: var(--secondary-border-color, #ddd);
  border-radius: 50%;
  border: 3px solid #f4f4f4;
  border-color: var(--secondary-bg-color, #f4f4f4);
}
.sjs-cb-switch input:checked + .sjs-cb-switch_control {
  background-color: #1ab394;
  background-color: var(--primary-color, #1ab394);
  border-color: #1ab394;
  border-color: var(--primary-color, #1ab394);
}
.sjs-cb-switch input:checked + .sjs-cb-switch_control .sjs-cb-switch__button {
  background-color: #f4f4f4;
  background-color: var(--secondary-bg-color, #f4f4f4);
  border-color: #1ab394;
  border-color: var(--primary-color, #1ab394);
  right: 0;
}
.sjs-cb-switch input:disabled + .sjs-cb-switch_control {
  cursor: not-allowed;
  background-color: #ddd;
  background-color: var(--secondary-border-color, #ddd);
  border-color: #ccc;
  border-color: var(--dd-menu-border, #ccc);
}
.sjs-cb-switch input:disabled + .sjs-cb-switch_control .sjs-cb-switch__button {
  background-color: #ccc;
  background-color: var(--dd-menu-border, #ccc);
  border-color: #ddd;
  border-color: var(--secondary-border-color, #ddd);
}