rating-item-editor {
  display: none;
}
rating-item-editor .svda-content {
  position: relative;
  pointer-events: none;
}
rating-item-editor .svda-content .svda-title-editor-content {
  white-space: nowrap;
}
rating-item-editor .svda-content .svda-title-editor-content:first-child {
  margin-left: 5px;
}
rating-item-editor .svda-content .svda-edit-button {
  padding-left: 5px;
  cursor: pointer;
}
rating-item-editor .svda-title-editor-start,
rating-item-editor .svda-delete-item {
  display: none;
}

.svda-add-new-rating-item {
  pointer-events: all;
  padding: 0 1em;
  line-height: 2em;
  display: none;
}

.svd_q_selected rating-item-editor {
  display: inline-block;
}
.svd_q_selected rating-item-editor .svda-content {
  pointer-events: all;
}
.svd_q_selected .item_editable:hover rating-item-editor .svda-title-editor-start,
.svd_q_selected .item_editable:hover rating-item-editor .svda-delete-item {
  display: inline-block;
}
.svd_q_selected .svda-add-new-rating-item {
  display: inline-block;
}