.form-control.svd_custom_select {
  padding: 0;
  min-width: 100px;
}
.form-control.svd_custom_select select {
  border: none;
  height: 34px;
  padding: 6px 12px;
  padding-right: 3em;
}
.form-control.svd_custom_select select:focus {
  outline: 0;
  box-shadow: none;
}
.form-control.svd_custom_select:before {
  top: -1px;
}

.svd_container .svd_content .svd_custom_select,
.svd_container .modal-dialog .svd_custom_select {
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  background-color: var(--primary-bg-color, #ffffff);
  box-sizing: content-box;
}
.svd_container .svd_content .svd_custom_select select,
.svd_container .modal-dialog .svd_custom_select select {
  width: 100%;
  display: block;
  position: relative;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 3em;
}
.svd_container .svd_content .svd_custom_select select::-ms-expand,
.svd_container .modal-dialog .svd_custom_select select::-ms-expand {
  display: none;
}
.svd_container .svd_content .svd_custom_select:before,
.svd_container .modal-dialog .svd_custom_select:before {
  padding: 17px;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("../images/ArrowDown_34x34.svg");
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}