.svd_container {
  .svd-tabs {
    padding: 19px 15px 0 30px;
  }

  .svd-tab.active > .svd-tab-text {
    color: $primary-color;
    color: var(--primary-color, $primary-color);
    background-color: $secondary-bg-color;
    background-color: var(--secondary-bg-color, $secondary-bg-color);
    border: 1px solid $primary-border-color;
    border-color: var(--primary-border-color, $primary-border-color);
    border-bottom-color: $secondary-bg-color;
    border-bottom-color: var(--secondary-bg-color, $secondary-bg-color);
  }

  .svd-tab-text {
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0.5em 1em;
    text-align: center;
    font-weight: bold;
    
    color: $primary-text-color;
    color: var(--primary-text-color, $primary-text-color);
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: $secondary-bg-color;
      background-color: var(--secondary-bg-color, $secondary-bg-color);
      border-bottom: 1px solid $secondary-bg-color;
      border-bottom-color: var(--secondary-bg-color, $secondary-bg-color);
    }
  }

}

.svd-survey-placeholder-root {
  .svd-survey-placeholder {
    position: relative;
    width: 100%;
    height: 500px;
    .svd-survey-gallery {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: $secondary-bg-color;
      background-color: var(--secondary-bg-color, $secondary-bg-color);
      margin: 6px;
      a {
        color: $primary-text-color;
        color: var(--primary-text-color, $primary-text-color);
        margin-top: 50px;
        font-size: 14px;
        text-decoration: underline;
        img {
          margin-top: -5px;
          margin-right: 1em;
        }
      }
    }
    .svd-empty-message-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .svd-empty-message {
        margin-bottom: 1em;
        text-align: center;
        font-size: 22px;
        color: $primary-color;
        color: var(--primary-color, $primary-color);
        pointer-events: none;
        max-width: 300px;
      }
      .svd-empty-image {
        pointer-events: none;
        display: block;
      }
      .svd-empty-action {
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
}

.svd_survey_designer {
  .svd-survey-placeholder-root {
    background-color: $primary-bg-color;
    background-color: var(--primary-bg-color, $primary-bg-color);
  }
}
