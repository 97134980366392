.svd-vertical-container {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
}

.svd-vertical-container__row {
    width: 100%;
    height: 100%;
    display: table-row;
    &:first-child {
        height: 0;
    }
}

.svd-vertical-container__cell {
    width: 100%;
    display: table-cell;
}
.svd-vertical-container__cell-content-holder {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.svd-vertical-container__cell-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.svd-vertical-container__cell-content--scrollable {
    overflow: auto;
}

.svd_editors {
    &>.svd-vertical-container {
        table-layout: fixed;
    }
}