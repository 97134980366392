.svd-toolbar-boolean {
  margin-right: 1em;
  line-height: 34px;
}
.svd-toolbar-boolean .svd-toolbar-boolean_check {
  display: inline-block;
  width: 30px;
  position: relative;
  height: 26px;
  vertical-align: middle;
}