@import "../colors";

svd-designer-h-container {
  vertical-align: top;
}

.svd-designer-h-container {
}

.svd-designer-container--top {
  width: 100%;
}
