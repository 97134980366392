.svd-translation-tab {
  height: 100%;
}
.svd-translation-tab .form-group {
  margin-top: 1em;
}
.svd-translation-tab .svd-translation-no-strings {
  min-height: 300px;
  text-align: center;
  line-height: 300px;
  vertical-align: middle;
  font-size: 40px;
}
.svd-translation-tab svd-translation-group {
  display: block;
}
.svd-translation-tab .svd-translation-strings {
  border-top-style: solid;
  border-top-width: 1px;
  padding: 10px;
}
.svd-translation-tab .svd-translation-panel-strings {
  padding: 10px;
  padding-left: 0;
}
.svd-translation-tab .svd-translation-panel-strings-title {
  font-weight: bold;
  line-height: 25px;
  padding: 5px 0;
}
.svd-translation-tab .svd-translation-panel-strings-title + svg-icon {
  line-height: 25px;
  display: inline-block;
  vertical-align: bottom;
}
.svd-translation-tab .svd-toolbox-category-header {
  margin-top: 20px;
}

.svd_container.sv_default_css .svd-translation-tab label {
  margin-bottom: 0;
}